import React from "react";

function YouTube() {
  const videoIds = [
    "W5cnwbaF12I",
    "_MKdGovIjTk",
    "CzXjP2WolbI",
    "vQqSGX_L1BI",
    "JmKDjzLruJE",
    "rivqlaKCzQ4",
    "92c2hU0W0Z0",
    "ffiGpNYOJ6w",
    "euhOpXh1tZ0",
    "HnpjIiXH9CQ",
    "qVzgonnKYGg",
    "0-5YmEaWBXM",
  ];

  return (
    <div className="flex flex-wrap justify-around lg:px-0 md:px-8 sm:px-0 gap-10 items-center">
      {videoIds.map((id) => (
        <div key={id}>
          <iframe
            className="rounded-xl w-[310px]"
            src={`https://www.youtube.com/embed/${id}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            title={`YouTube video ${id}`}
          ></iframe>
        </div>
      ))}
    </div>
  );
}

export default YouTube;
