import React, { useState, useEffect } from "react";
import ShowPopup from "./ShowPopUp";
import {
  fetchAndDisplayImages,
  fetchSingleImage,
  getPictureWithUpdatedMetadata,
  handleUpdateMataData,
  moveToDirectory,
  deleteImage,
  reserveImage,
} from "../../helper_functions/aws_helper_functions";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { IoMailOutline } from "react-icons/io5";

function ImgGallery({
  filter,
  searchTerm,
  isAdmin,
  addedNewPicture,
  chosenLanguage,
}) {
  const { t, i18n } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [imagesView, setImagesView] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [sawPopup, setSawPopup] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [changedMetadata, setChangedMetadata] = useState(false);
  const [loading, setLoading] = useState(true);
  const [allAvailablePicturesLoaded, setAllAvailablePicturesLoaded] =
    useState(false);
  const [allSoldPicturesLoaded, setAllSoldPicturesLoaded] = useState(false);
  const [availablePictures, setAvailablePictures] = useState([]);
  const [soldPictures, setSoldPictures] = useState([]);
  const [stillSearching, setStillSearching] = useState(false);
  const [editingImage, setEditingImage] = useState(null);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [editingNaslov, setEditingNaslov] = useState("");
  const [editingTitle, setEditingTitle] = useState("");
  const [editingXDims, setEditingXDims] = useState("");
  const [editingYDims, setEditingYDims] = useState("");
  const [clickedMarkAsSold, setClickedMarkAsSold] = useState(null);
  const [updatedMetadata, setUpdatedMetadata] = useState("");
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [deleteImageInfo, setDeleteImageInfo] = useState(null);

  const loadMorePictures = () => {
    setLoading(true);
    const folder = filter === "available" ? "available" : "sold";
    fetchAndDisplayImages(folder, false).then(([data, picturesLoaded]) => {
      let pictures = [...imagesView, ...data];
      setImagesView(pictures);
      if (filter === "available") {
        setAvailablePictures(pictures);
        setAllAvailablePicturesLoaded(picturesLoaded);
      } else {
        setSoldPictures(pictures);
        setAllSoldPicturesLoaded(picturesLoaded);
      }
      setLoading(false);
    });
  };

  const fetchImages = (fromBegging) => {
    fetchAndDisplayImages("available", fromBegging).then(
      ([data, picturesLoaded]) => {
        setAvailablePictures(data);
        setLoading(false);
        setAllAvailablePicturesLoaded(picturesLoaded);
        if (filter === "available") {
          setImagesView(data);
        }
      }
    );
    fetchAndDisplayImages("sold", fromBegging).then(
      ([data, picturesLoaded]) => {
        setSoldPictures(data);
        setLoading(false);
        setAllSoldPicturesLoaded(picturesLoaded);
        if (filter === "sold") {
          setImagesView(data);
        }
      }
    );
  };

  useEffect(() => {
    if (!showPopup && sawPopup) {
      loadMorePictures();
    }
  }, [showPopup]);

  const handleNameChange = (event) => {
    setEditingTitle(event.target.value);
  };

  const handleNaslovSlikeChange = (event) => {
    setEditingNaslov(event.target.value);
  };

  const handleDimXChange = (event) => {
    setEditingXDims(event.target.value);
  };

  const handleDimYChange = (event) => {
    setEditingYDims(event.target.value);
  };

  useEffect(() => {
    if (
      (!allAvailablePicturesLoaded || !allSoldPicturesLoaded) &&
      filteredResults?.length === 0 &&
      searchTerm !== ""
    ) {
      setStillSearching(true);
    } else {
      setStillSearching(false);
    }
  }, [searchTerm]);

  useEffect(() => {
    fetchSingleImage().then((data) => {
      if (data) {
        const pictures = [data, ...imagesView];
        const newAvailablePictures = [data, ...availablePictures];
        setImagesView(pictures);
        setAvailablePictures(newAvailablePictures);
      }
    });
  }, [addedNewPicture]);

  useEffect(() => {
    if (filter === "available") {
      setImagesView(availablePictures);
    } else {
      setImagesView(soldPictures);
    }
  }, [filter]);

  useEffect(() => {
    if (deleteImageInfo?.success) {
      if (filter === "available") {
        const newAvailablePictures = availablePictures?.filter(
          (img) => img.url !== deleteImageInfo?.url
        );
        setImagesView(newAvailablePictures);
      } else {
        const newSoldPictures = soldPictures?.filter(
          (img) => img.url !== deleteImageInfo?.url
        );
        setImagesView(newSoldPictures);
      }
    }
  }, [deleteImageInfo]);

  useEffect(() => {
    if (clickedMarkAsSold) {
      if (filter === "available") {
        const newAvailablePictures = availablePictures?.filter(
          (img) => img.url !== clickedMarkAsSold
        );
        setImagesView(newAvailablePictures);
        setClickedMarkAsSold(null);
      } else {
        const newSoldPictures = soldPictures?.filter(
          (img) => img.url !== clickedMarkAsSold
        );
        setImagesView(newSoldPictures);
        setClickedMarkAsSold(null);
      }
    } else {
      if (updatedMetadata) {
        getPictureWithUpdatedMetadata(updatedMetadata).then((data) => {
          if (filter === "available") {
            const newAvailablePictures = availablePictures?.map((img) => {
              const compareObjectKey = img.url.split(".com/")[1];
              if (compareObjectKey === updatedMetadata) {
                return data;
              }
              return img;
            });
            setImagesView(newAvailablePictures);
            setAvailablePictures(newAvailablePictures);
          } else {
            const newSoldPictures = soldPictures?.map((img) => {
              if (img.url === updatedMetadata) {
                return data;
              }
              return img;
            });
            setImagesView(newSoldPictures);
          }
        });
      }
    }
  }, [changedMetadata]);

  useEffect(() => {
    fetchImages();
    // Funkcija za ažuriranje širine ekrana prilikom promene veličine prozora
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Dodavanje event listenera za praćenje promene veličine prozora
    window.addEventListener("resize", handleWindowResize);

    // Uklanjanje event listenera prilikom unmount-a komponente
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleOpenPopup = (event, img, metadata) => {
    // Provera širine ekrana
    if (windowWidth >= 768 && event.target.tagName === "IMG") {
      setCurrentImage({ img, metadata });
      setShowPopup(true);
      setSawPopup(false);
      document.body.style.overflow = "hidden";
    }
  };

  const filteredImages = imagesView?.filter((img) => {
    if (img.metadata["title"] === undefined) {
      return;
    }
    if (!!!img.metadata["deleted"]) {
      return (
        (filter === "sold" || filter === "available") &&
        (img.metadata["title"]
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
          decodeURIComponent(img.metadata["titlesrb"])
            .toLowerCase()
            .includes(searchTerm.toLowerCase()))
      );
    }
  });

  const filteredResults = filteredImages?.filter((img) => {
    if (!!!img.metadata["deleted"]) {
      return (
        img.metadata["title"]
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        decodeURIComponent(img.metadata["titlesrb"])
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    }
  });

  const handleEditImage = (url, metadata) => {
    setEditingImage(url);
    setIsEditingMode(true);
  };

  const handleClosePopup = () => {
    setCurrentImage(null);
    setShowPopup(false);
    setSawPopup(true);
    document.body.style.overflow = "auto";
  };

  const handleSendRequest = (img, title) => {
    const subject = `Request for ${title}`;
    const mailtoURL = `mailto:dusandjukaric@yahoo.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(img)}`;

    window.open(mailtoURL);
  };

  const handlePopupClick = (event) => {
    if (event.target.classList.contains("popup-image")) {
      event.stopPropagation();
    } else {
      handleClosePopup();
    }
  };

  const showEditPencile = (url, metadata) => {
    if (isAdmin) {
      return (
        <button
          className="hover:shadow-md px-2 rounded"
          onClick={() => handleEditImage(url, metadata)}
        >
          <ion-icon name="pencil-outline"></ion-icon>
        </button>
      );
    }
  };

  // Delete button
  const showDelete = (url, metadata) => {
    if (isAdmin) {
      return (
        <button
          className="hover:shadow-md px-2 rounded"
          onClick={() => {
            setDeleteIsOpen(true);
            setDeleteImageInfo({ url, metadata });
          }}
        >
          <ion-icon name="trash-bin-outline"></ion-icon>
        </button>
      );
    }
  };

  const closeDelete = () => {
    setDeleteIsOpen(false);
  };

  // Reservation button
  const showReserved = (url, metadata) => {
    const objectKey = url?.split(".com/")[1];
    if (isAdmin) {
      return (
        <button
          className="hover:shadow-md px-2 rounded"
          onClick={async () => {
            reserveImage(url, metadata).then((res) => {
              setChangedMetadata(!changedMetadata);
              setUpdatedMetadata(objectKey);
            });
          }}
        >
          <ion-icon name="person-add-outline"></ion-icon>
        </button>
      );
    }
  };

  const handleNewMetadata = (url, metadata) => {
    setIsEditingMode(false);
    const objectKey = url.split(".com/")[1];
    const title = editingTitle === "" ? metadata["title"] : editingTitle;
    const naslov =
      editingNaslov === ""
        ? metadata["titlesrb"]
        : encodeURIComponent(editingNaslov);
    const x_dim = editingXDims === "" ? metadata["x_dim"] : editingXDims;
    const y_dim = editingYDims === "" ? metadata["y_dim"] : editingYDims;
    const newMetadata = {
      title: title,
      titlesrb: naslov,
      x_dim: x_dim,
      y_dim: y_dim,
    };
    handleUpdateMataData(editingImage, newMetadata).then(() => {
      setChangedMetadata(!changedMetadata);
      setUpdatedMetadata(objectKey);
    });
  };

  const getTitleForLanguage = (lang, metadata) => {
    if (lang === "eng" || lang === "en") {
      return metadata["title"];
    } else {
      return decodeURIComponent(metadata["titlesrb"]);
    }
  };
  const showButton = (url, metadata) => {
    if (isAdmin) {
      if (isEditingMode && editingImage === url) {
        return (
          <button onClick={() => handleNewMetadata(url, metadata)}>Save</button>
        );
      } else if (filter === "sold") {
        return (
          <button
            className="border border-gray-500 rounded p-2 hover:shadow-md  opacity-20 hover:opacity-25"
            onClick={() => {
              moveToDirectory(url, false).then(() => {
                setChangedMetadata(!changedMetadata);
                setClickedMarkAsSold(url);
              });
            }}
          >
            Mark as Available
          </button>
        );
      } else if (filter === "available") {
        return (
          <button
            className="border border-gray-500 rounded p-2 hover:shadow-md"
            onClick={() => {
              const newMetadata = {
                ...metadata,
              };
              newMetadata["sold"] = "true";
              moveToDirectory(url, true).then(() => {
                setChangedMetadata(!changedMetadata);
                setClickedMarkAsSold(url);
              });
            }}
          >
            Mark as Sold
          </button>
        );
      }
    }
    return (
      <span
        className="my-auto m-1 rounded px-2 text-[20px] xl:text-[23px] hover:shadow-md hover:bg-neutral-50 transition-all duration-300 ease-in"
        onClick={() => handleSendRequest(url, metadata["title"])}
      >
        {/* SEND REQUEST */}
        {/*<ion-icon name="mail-outline"></ion-icon>*/}
        <IoMailOutline />
      </span>
    );
  };

  function loadingMessage() {
    if (loading) {
      if (stillSearching) {
        return (
          <>
            <p className="text-center text-2xl text-gray-500">
              {t("stillSearching")}
            </p>
            <p className="text-center text-gray-500">{t("pleaseWait")}</p>
          </>
        );
      } else {
        return (
          <>
            <p className="text-center text-2xl text-gray-500">{t("loading")}</p>
            <p className="text-center text-gray-500">{t("pleaseWait")}</p>
          </>
        );
      }
    }
    return (
      <>
        <p className="text-center text-2xl text-gray-500">{t("noResult")}</p>
        <p className="text-center text-gray-500">{t("resultText")}</p>
      </>
    );
  }

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 my-10 md:mx-12 lg:mx-20  items-center">
        {filteredResults?.map(({ url, metadata }) => {
          return (
            <div
              className="relative"
              key={url}
              onClick={(event) => handleOpenPopup(event, url, metadata)}
            >
              {filter === "sold" && (
                <div className="absolute top-0 right-0 bg-neutral-50 px-2 border-t border-r border-gray-200">
                  <p>{t("sold")}</p>
                </div>
              )}
              {"reserved" in metadata && metadata["reserved"] === "true" && (
                <div className="absolute top-0 right-0 bg-neutral-50 px-2 border-t border-r border-gray-200">
                  <p>{t("reserved")}</p>
                </div>
              )}
              <img
                src={url}
                alt="Paintings, Watercolor, Dusan Djukaric"
                className="max-h-[600px] md:w-full mx-auto object-cover shadow-md"
              />
              <div className="flex justify-between mt-2">
                {isEditingMode && editingImage === url ? (
                  <div className="p-1">
                    <input
                      type="text"
                      defaultValue={metadata["title"]}
                      onChange={handleNameChange}
                      className="text-[11px] xl:text-[12px] text-gray-500"
                      placeholder="Title"
                    />
                    <input
                      type="text"
                      defaultValue={decodeURIComponent(metadata["titlesrb"])}
                      onChange={handleNaslovSlikeChange}
                      className="text-[11px] xl:text-[12px] text-gray-500"
                      placeholder="Naslov"
                    />
                    <input
                      type="number"
                      defaultValue={metadata["x_dim"]}
                      onChange={handleDimXChange}
                      className="text-[11px] xl:text-[12px] text-gray-500"
                      placeholder="X dimension cm"
                    />
                    <input
                      type="number"
                      defaultValue={metadata["y_dim"]}
                      onChange={handleDimYChange}
                      className="text-[11px] xl:text-[12px] text-gray-500"
                      placeholder="Y dimension cm"
                    />
                  </div>
                ) : (
                  <div className="p-1">
                    <p>{getTitleForLanguage(i18n.language, metadata)}</p>
                    <p className="text-[11px] xl:text-[12px] text-gray-500">
                      {t("dimension")} {metadata["x_dim"]} x {metadata["y_dim"]}{" "}
                      {t("cm")}
                    </p>
                    <p className="text-[11px] xl:text-[12px] text-gray-500">
                      {t("technique")}
                    </p>
                  </div>
                )}
                {showReserved(url, metadata)}
                {showDelete(url, metadata)}
                {showEditPencile(url, metadata)}
                {showButton(url, metadata)}
              </div>
            </div>
          );
        })}
      </div>
      <div>
        {filteredResults?.length === 0 && (
          <div className="h-[100px] mb-20">{loadingMessage()}</div>
        )}
      </div>
      {!allAvailablePicturesLoaded && filter === "available" && (
        <span
          className="cursor-pointer flex flex-col justify-center items-center"
          onClick={() => loadMorePictures()}
        >
          {loading ? (
            <>
              <span className="text-slate-500 animate-spin">
                <ion-icon name="reload-outline"></ion-icon>
              </span>
              <p className="text-slate-500">{t("loadingPaintings")}</p>
            </>
          ) : (
            <p>{t("loadMore")}</p>
          )}
        </span>
      )}
      {!allSoldPicturesLoaded && filter === "sold" && (
        <span
          className="cursor-pointer flex flex-col justify-center items-center"
          onClick={() => loadMorePictures()}
        >
          {loading ? (
            <>
              <span className="text-slate-500 animate-spin">
                <ion-icon name="reload-outline"></ion-icon>
              </span>
              <p className="text-slate-500">{t("loadingPaintings")}</p>
            </>
          ) : (
            <p>{t("loadMore")}</p>
          )}
        </span>
      )}
      <ShowPopup
        showPopup={showPopup}
        currentImage={currentImage}
        handleClosePopup={handleClosePopup}
        handleSendRequest={handleSendRequest}
        handlePopupClick={handlePopupClick}
        currentImageArray={filteredImages}
      />
      {deleteIsOpen && (
        <Modal
          isOpen={deleteIsOpen}
          onRequestClose={closeDelete}
          contentLabel="Poruka"
        >
          <div className="grid grid-col justify-center items-center my-auto">
            <h2 className="mt-10 text-xl text-slate-700">
              Da li ste sigurni da želite da obrišete ovu sliku?
            </h2>
            <p className="mx-auto mt-[100px] mb-10 text-4xl text-slate-700">
              {decodeURIComponent(deleteImageInfo?.metadata["titlesrb"])}
            </p>
            <p className="mx-auto mb-[130px] text-slate-500">
              Ovo je trajna izmena.
            </p>
            <button
              onClick={closeDelete}
              className="text-slate-700 border rounded my-10 py-3 hover:shadow-md hover:bg-slate-200 hover:text-slate-500"
            >
              Zatvori
            </button>
            <button
              className="text-slate-500 border rounded py-3 hover:shadow-md hover:text-slate-700 hover:bg-slate-200"
              onClick={() => {
                deleteImage(deleteImageInfo).then(() => {
                  setDeleteImageInfo({ ...deleteImageInfo, success: true });
                });
                closeDelete();
              }}
            >
              OBRIŠI SLIKU
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ImgGallery;
